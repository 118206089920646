import {Text} from '@chakra-ui/react';
import {QuoteStyled} from './Quote.style';

type WPQuoteProps = {
  content: string | JSX.Element;
};

export default function WPQuote(props: WPQuoteProps): JSX.Element {
  const {content} = props;

  return (
    <QuoteStyled>
      <figure className='wp-block-pullquote'>
        <blockquote>
          {typeof content === 'string' ? (
            <Text as='p' dangerouslySetInnerHTML={{__html: content}} />
          ) : (
            content
          )}
        </blockquote>
      </figure>
    </QuoteStyled>
  );
}
