import {Flex} from '@chakra-ui/react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

export default function ShareButtons(): JSX.Element {
  const shareUrl = location.href;
  const title = document.title;

  return (
    <Flex justify={'center'} margin='0 auto'>
      <Flex gap='2'>
        <FacebookShareButton url={shareUrl} quote={title}>
          <FacebookIcon size={28} round />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon size={28} round />
        </TwitterShareButton>
        <TelegramShareButton url={shareUrl} title={title}>
          <TelegramIcon size={28} round />
        </TelegramShareButton>
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={28} round />
        </LinkedinShareButton>
        <RedditShareButton url={shareUrl} title={title} windowWidth={660} windowHeight={460}>
          <RedditIcon size={28} round />
        </RedditShareButton>
        <TumblrShareButton url={shareUrl} title={title}>
          <TumblrIcon size={28} round />
        </TumblrShareButton>
        <EmailShareButton url={shareUrl} subject={title} body='body'>
          <EmailIcon size={28} round />
        </EmailShareButton>
      </Flex>
    </Flex>
  );
}
