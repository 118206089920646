import {useToast} from '@chakra-ui/react';
import WPQuote from 'components/Quote/WPQuote';
import ShareButtons from 'components/ShareButtons/ShareButtons';
import {useEffect, useState} from 'react';
import {copyTextToClipboard} from 'utilities/helpers';
import {TimeStampStyled} from './TimeStamp.style';

export default function TimeStamp(): JSX.Element {
  const toast = useToast();

  const handleOnClick = textToCopy => {
    copyTextToClipboard(textToCopy).then(
      function () {
        toast({
          title: 'Timestamp liegt in deiner Zwischenablage ❤️️',
          description: textToCopy,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
      function (error) {
        console.error(error);
        toast({
          title: 'Deine Zwischenablage scheint nicht zu funktionieren 😰',
          description: textToCopy,
          status: 'warning',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    );
  };

  const [currentTimestamp, setCurrentTimestamp] = useState<Date>(new Date(Date.now()));

  const intervalFunc = () =>
    setInterval(() => {
      setCurrentTimestamp(new Date(Date.now()));
    }, 1000);

  useEffect(() => {
    const interval = intervalFunc();

    return () => clearInterval(interval);
  }, []);

  const seconds = currentTimestamp.getTime();
  const dateString = currentTimestamp.toLocaleString();

  return (
    <>
      <WPQuote
        content={
          <>
            <TimeStampStyled>
              <span onClick={() => handleOnClick(seconds)}>{seconds}</span>
              <span onClick={() => handleOnClick(dateString)}>{dateString}</span>
              <br />
              <strong>🤯 Klick 🤯</strong>
            </TimeStampStyled>

            <br />
            <cite>
              <br />
              <ShareButtons />
            </cite>
          </>
        }
      ></WPQuote>
    </>
  );
}
